<template>
  <div class="deletePostConfirmContent">
    <div dpadding>
      <div tcenter strong>{{ $locale["delete_post_confirm"] }}</div>
      <Spacer num="1" />
      <div tcenter dpadding v-if="$lockapp"><iconic dcolor name="gspinner" /></div>
      <div v-else>
        <Confirm :confirm="{ accept: { label: $locale['delete'] }, cancel: { label: $locale['cancel'] } }" @cancel="close" @accept="deletePost" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["index", "modal"],
  methods: {
    close: function() {
      this.modal.close();
    },
    deletePost: async function() {
      this.lockapp(true);
      try {
        this.delete = await this.$api.post(`${this.$apiHost}/posts/delete`, { postId: this.modal.post._id });
        this.deleteResponse(this.delete.data);
      } catch (error) {
        this.alert(error);
      }
      this.lockapp(false);
    },
    deleteResponse: function({ message, success, post }) {
      if (!success) {
        this.alert(message);
        return;
      }
      this.modal.post.deleted = post.deleted;
      this.modal.close();
    },
  },
};
</script>
